@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.capitalize-first {
    text-transform: lowercase;
}

.capitalize-first::first-letter {
    text-transform: uppercase !important;
}